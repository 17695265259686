import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { Accordion, Modal } from "bootstrap";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import VueGtag from "vue-gtag";

// require("aframe");
// require("aframe-orbit-controls");

createApp(App)
  .use(router)
  .use(VueSweetalert2)
  .use(VueGtag, {
    config: { id: "G-2FGH9G94MW" },
  })

  .mount("#app");
