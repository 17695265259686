<template>
  <div id="mediaPipeView">
    <MediaPipe2 />
  </div>
</template>

<script>
import MediaPipe2 from "@/components/MediaPipe2.vue";

export default {
  name: "ConnectionState",
  components: {
    MediaPipe2,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss">
#mediaPipeView {
  width: 100vw;
  height: 100vh;
  background-color: aqua;
}
</style>
