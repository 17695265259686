<template>
  <div class="cameraViewContainer">
    <button id="clickButton" @click="toggleCam()">Toggle the camera</button>
    <div class="checkbox_holder">
      <div id="checkbox_0" class="checkbox_container">
        <input type="checkbox" class="checkbox_box" v-model="cat_0.show" />
        <label for="checkbox">{{ cat_0.name }}</label>
      </div>
      <div id="checkbox_1" class="checkbox_container">
        <input type="checkbox" class="checkbox_box" v-model="cat_1.show" />
        <label for="checkbox">{{ cat_1.name }}</label>
      </div>
      <div id="checkbox_2" class="checkbox_container">
        <input type="checkbox" class="checkbox_box" v-model="cat_2.show" />
        <label for="checkbox">{{ cat_2.name }}</label>
      </div>
      <div id="checkbox_3" class="checkbox_container">
        <input type="checkbox" class="checkbox_box" v-model="cat_3.show" />
        <label for="checkbox">{{ cat_3.name }}</label>
      </div>
      <div id="checkbox_4" class="checkbox_container">
        <input type="checkbox" class="checkbox_box" v-model="cat_4.show" />
        <label for="checkbox">{{ cat_4.name }}</label>
      </div>
      <div id="checkbox_5" class="checkbox_container">
        <input type="checkbox" class="checkbox_box" v-model="cat_5.show" />
        <label for="checkbox">{{ cat_5.name }}</label>
      </div>
      <div id="checkbox_6" class="checkbox_container">
        <input type="checkbox" class="checkbox_box" v-model="cat_6.show" />
        <label for="checkbox">{{ cat_6.name }}</label>
      </div>
      <div id="checkbox_7" class="checkbox_container">
        <input type="checkbox" class="checkbox_box" v-model="cat_7.show" />
        <label for="checkbox">{{ cat_7.name }}</label>
      </div>
      <div id="checkbox_7" class="checkbox_container">
        <input type="checkbox" class="checkbox_box" v-model="threeZ" />
        <label for="checkbox">{{ cat_7.name }} Z Index</label>
      </div>
    </div>
    <div id="cameraBox">
      <div class="webcam">
        <video
          id="webcam"
          class="vidPreviewC"
          width="1280"
          height="720"
          autoplay
        ></video>
        <canvas
          id="canvas"
          width="1280"
          class="vidPreviewC"
          display="none"
          height="720"
        ></canvas>
        <canvas
          id="canvas2"
          width="1280"
          v-show="cat_6.show"
          class="vidPreviewC"
          height="720"
        ></canvas>

        <canvas
          id="canvas3"
          width="1280"
          class="vidPreviewC"
          height="720"
        ></canvas>
      </div>
      <div id="threeParent" v-show="cat_7.show"></div>
    </div>

    <div id="angleShow">{{}}...<br />{{ this.shoulderAngleDegrees }}</div>
  </div>
</template>

<script>
// import {
//   ImageSegmenter,
//   FilesetResolver,
//   PoseLandmarker,
//   DrawingUtils,
// } from "mediapipe/tasks-vision@0.10.2";

var tasksVision = require("@mediapipe/tasks-vision");
const ImageSegmenter = tasksVision.ImageSegmenter;

const FilesetResolver = tasksVision.FilesetResolver;
const PoseLandmarker = tasksVision.PoseLandmarker;
const DrawingUtils = tasksVision.DrawingUtils;
console.log(tasksVision);
import { loadRouteLocation } from "vue-router";

import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
export default {
  name: "CameraViewComponent",
  components: {},
  props: ["sendData"],
  data() {
    return {
      w_video_1: null,

      w_canvas_1: null,
      w_ctx_1: null,

      w_canvas_2: null,
      w_ctx_2: null,
      w_canvas_3: null,
      w_ctx_3: null,
      webcam_running: false,
      v_width: "1280px",
      v_height: "720px",
      w_running_mode: "VIDEO",
      w_segmenter: null,
      imageSegmenter: null,
      pose_landmarker: null,
      drawingUtils: null,
      lastWebcamTime: -1,
      lock: false,
      yDelta: 0,
      camView: true,
      endFrame: 0,
      startFrame: 0,
      mp: [
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
        {},
      ],
      cat_0: { show: true, name: "Background" },
      cat_1: { show: true, name: "Hair" },
      cat_2: { show: true, name: "Body (Skin)" },
      cat_3: { show: true, name: "Face (Skin)" },
      cat_4: { show: true, name: "Clothing" },
      cat_5: { show: true, name: "Other" },
      cat_6: { show: true, name: "Skeleton" },
      cat_7: { show: true, name: "ThreeJS" },
      spherePos: { x: 0, y: 0, z: 0 },
      leftWrist: new THREE.Vector3(),
      mpLS: null,
      mpLE: null,
      mpRS: null,
      mpRE: null,
      rightAngle: 0,
      rightAngleR: 0,
      leftAngle: 0,
      leftAngleL: 0,
      globalRot: 0,
      shoulderX: 0,
      shoulderR: 0,
      shoulderAngleDegrees: 0,
      mpLSz: 0,
      leftShoulderPoint: null,
      rightShoulderPoint: null,
      threeZ: true,
      angleL: [],
      angleR: [],
    };
  },
  mounted() {
    !(function (t, e) {
      var o, n, p, r;
      e.__SV ||
        ((window.posthog = e),
        (e._i = []),
        (e.init = function (i, s, a) {
          function g(t, e) {
            var o = e.split(".");
            2 == o.length && ((t = t[o[0]]), (e = o[1])),
              (t[e] = function () {
                t.push([e].concat(Array.prototype.slice.call(arguments, 0)));
              });
          }
          ((p = t.createElement("script")).type = "text/javascript"),
            (p.async = !0),
            (p.src = s.api_host + "/static/array.js"),
            (r = t.getElementsByTagName("script")[0]).parentNode.insertBefore(
              p,
              r
            );
          var u = e;
          for (
            void 0 !== a ? (u = e[a] = []) : (a = "posthog"),
              u.people = u.people || [],
              u.toString = function (t) {
                var e = "posthog";
                return (
                  "posthog" !== a && (e += "." + a), t || (e += " (stub)"), e
                );
              },
              u.people.toString = function () {
                return u.toString(1) + ".people (stub)";
              },
              o =
                "capture identify alias people.set people.set_once set_config register register_once unregister opt_out_capturing has_opted_out_capturing opt_in_capturing reset isFeatureEnabled onFeatureFlags getFeatureFlag getFeatureFlagPayload reloadFeatureFlags group updateEarlyAccessFeatureEnrollment getEarlyAccessFeatures getActiveMatchingSurveys getSurveys".split(
                  " "
                ),
              n = 0;
            n < o.length;
            n++
          )
            g(u, o[n]);
          e._i.push([i, s, a]);
        }),
        (e.__SV = 1));
    })(document, window.posthog || []);
    // eslint-disable-next-line
    posthog.init("phc_QtxEVH0HRPZcF9ZgxBHR23IIVKXNTkkNvcxuafEbKVN", {
      api_host: "https://eu.posthog.com",
    });

    this.w_video_1 = document.getElementById("webcam");

    this.w_canvas_1 = document.getElementById("canvas");
    this.w_ctx_1 = this.w_canvas_1.getContext("2d", {
      willReadFrequently: true,
    });

    this.getWebcam();

    this.createPoseLandmarker();
    this.createImageSegmenter();
    setTimeout(() => {
      this.poseDetection();
      this.imageSegmentation();
    }, 1000);

    this.initThree();
  },
  watch: {
    threeZ(o, n) {
      if (n == true) {
        document.getElementById("three_canvas").style.zIndex = 100;
      } else {
        document.getElementById("three_canvas").style.zIndex = 2;
      }
    },
  },
  methods: {
    getWebcam: async function () {
      const constraints = {
        video: {
          width: { ideal: 1280 },
          height: { ideal: 720 },
          deviceId:
            "2260dba3f864aa59d1a86afc4b996cd9640efcf339f3950a3baeeff957988e56",
          // deviceId:
          //   "7bdc7713d59611154b3529ec80cd497a75e02dd02539582950658c16debb2325",
          // deviceId:
          //   "eb856cac40e8e84a86b2fcf95f32b38b0557bdd6d158543d277196bf6c9eb0ed",
        },
      };
      //   deviceId: 'eb856cac40e8e84a86b2fcf95f32b38b0557bdd6d158543d277196bf6c9eb0ed'
      // Activate the webcam stream.
      this.w_video_1.srcObject = await navigator.mediaDevices.getUserMedia(
        constraints
      );
    },
    toggleCam: function () {
      if (!this.camView) {
        this.camView = true;
      } else {
        this.camView = false;
      }
    },
    createPoseLandmarker: async function () {
      console.log("create pose landmarker");
      const vision2 = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.2/wasm"
      );
      this.pose_landmarker = await PoseLandmarker.createFromOptions(vision2, {
        baseOptions: {
          modelAssetPath: `https://storage.googleapis.com/mediapipe-models/pose_landmarker/pose_landmarker_heavy/float16/latest/pose_landmarker_heavy.task`,
          delegate: "GPU",
        },
        runningMode: this.running_mode,
        min_tracking_confidence: 1.0,
        numPoses: 1,
      });
    },
    createImageSegmenter: async function () {
      console.log("create image seg");
      const audio = await FilesetResolver.forVisionTasks(
        "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.2/wasm"
      );

      this.image_segmenter = await ImageSegmenter.createFromOptions(audio, {
        baseOptions: {
          modelAssetPath:
            "https://storage.googleapis.com/mediapipe-models/image_segmenter/selfie_multiclass_256x256/float32/latest/selfie_multiclass_256x256.tflite?v=alkali.mediapipestudio_20240517_0657_RC00",
          delegate: "GPU",
        },
        runningMode: "IMAGE",
        outputCategoryMask: true,
        outputConfidenceMasks: true,
      });
    },
    callbackForVideo: function (result) {
      let imageData = this.w_ctx_1.getImageData(
        0,
        0,
        document.getElementById(this.w_video_1.id).videoWidth,
        document.getElementById(this.w_video_1.id).videoHeight,
        { willReadFrequently: true }
      ).data;

      const uint8Array = new Uint8ClampedArray(imageData.buffer);

      const dataNew = new ImageData(
        uint8Array,
        this.w_video_1.videoWidth,
        this.w_video_1.videoHeight
      );

      this.w_ctx_1.putImageData(dataNew, 0, 0);

      // if (this.webcam_running === true) {

      // }
    },
    callbackforSeg: function (result) {
      let imageData = this.w_ctx_1.getImageData(
        0,
        0,
        document.getElementById(this.w_video_1.id).videoWidth,
        document.getElementById(this.w_video_1.id).videoHeight,
        { willReadFrequently: true }
      ).data;

      console.log(result);
      const mask = result.categoryMask.getAsFloat32Array();
      const cmask = result.confidenceMasks[4].getAsFloat32Array();
      // console.log(cmask);
      let j = 0;

      const cat0 = this.cat_0.show;
      const cat1 = this.cat_1.show;
      const cat2 = this.cat_2.show;
      const cat3 = this.cat_3.show;
      const cat4 = this.cat_4.show;
      const cat5 = this.cat_5.show;
      for (let i = 0; i < mask.length; ++i) {
        const maskVal = Math.round(mask[i] * 255.0);
        // console.log(maskVal)
        // const maskVal = mask[i]
        // console.log('mask is', maskVal)

        if (maskVal == 0 && cat0 == false) {
          imageData[j + 3] = 0;
        } else if (maskVal == 1 && cat1 == false) {
          imageData[j + 3] = 0;
        } else if (maskVal == 2 && cat2 == false) {
          imageData[j + 3] = 0;
        } else if (maskVal == 3 && cat3 == false) {
          imageData[j + 3] = 0;
        } else if (maskVal == 4 && cat4 == false && cmask[i] < 0.999) {
          imageData[j + 3] = 0;
        } else if (maskVal == 5 && cat5 == false) {
          imageData[j + 3] = 0;
        }
        j += 4;
      }

      const uint8Array = new Uint8ClampedArray(imageData.buffer);
      // const uint8Array2 = new Uint8ClampedArray(imageData2.buffer)
      const dataNew = new ImageData(uint8Array, 1280, 720);
      this.w_ctx_3.putImageData(dataNew, 0, 0);

      window.requestAnimationFrame(this.predictWebcam);
    },
    hasGetUserMedia: function () {
      return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);
    },

    predictWebcam: async function () {
      this.lastWebcamTime = this.w_video_1.currentTime;
      this.w_ctx_1.drawImage(
        this.w_video_1,
        0,
        0,
        this.w_video_1.videoWidth,
        this.w_video_1.videoHeight
      );

      // if image mode is initialized, create a new segmented with video runningMode
      if (this.lock == false) {
        this.lock = true;

        await this.pose_landmarker.setOptions({ runningMode: "VIDEO" });
        await this.image_segmenter.setOptions({ runningMode: "VIDEO" });
      }
      let startTimeMs = performance.now();

      // console.log('video id is ', this.w_video_1.id)

      this.image_segmenter.segmentForVideo(
        this.w_video_1,
        startTimeMs,
        async (result) => {
          this.callbackforSeg(result);
        }
      );

      this.pose_landmarker.detectForVideo(
        this.w_video_1,
        startTimeMs,
        async (result) => {
          this.callbackForVideo();
          this.w_ctx_2.save();
          this.w_ctx_2.clearRect(
            0,
            0,
            this.w_canvas_2.width,
            this.w_canvas_2.height
          );

          if (result.landmarks.length > 0) {
            this.l_hip_vid = result.landmarks[0][23];
            this.r_hip_vid = result.landmarks[0][24];

            this.mpLS = result.landmarks[0][11];
            this.mpLE = result.landmarks[0][13];

            this.mpRS = result.landmarks[0][12];
            this.mpRE = result.landmarks[0][14];
            // console.log(result);
          }
          // result.landmarks[0].forEach((el, i) => {
          //   this.raycaster.setFromCamera(
          //     { x: el.x * 2 - 1, y: (el.y * 2 - 1) * -1 },
          //     this.camera
          //   );
          //   const intersects = this.raycaster.intersectObjects(
          //     this.scene.children,
          //     true
          //   );

          //   if (intersects.length > 0) {
          //     const intersect = intersects[0];
          //     // console.log("3D Coordinate:", intersect.point);
          //     this.mp[i] = intersect.point;
          //   }
          // });

          // let nose = result.landmarks[0][0];
          // console.log(nose);
          // this.raycaster.setFromCamera(
          //   { x: nose.x * 2 - 1, y: (nose.y * 2 - 1) * -1 },
          //   this.camera
          // );

          // const intersects = this.raycaster.intersectObjects(
          //   this.scene.children,
          //   true
          // );

          // if (intersects.length > 0) {
          //   const intersect = intersects[0];
          //   // console.log("3D Coordinate:", intersect.point);
          //   this.nose = intersect.point;
          //   console.log("nose is ", this.nose);
          //   this.spherePos.x = this.nose.x;
          //   this.spherePos.y = this.nose.y;
          //   this.spherePos.z = -1;
          // }

          // let wrist = result.landmarks[0][15];
          // this.raycaster.setFromCamera(
          //   { x: wrist.x * 2 - 1, y: (wrist.y * 2 - 1) * -1 },
          //   this.camera
          // );

          // const intersects2 = this.raycaster.intersectObject(this.plane);

          // if (intersects2.length > 0) {
          // console.log("instersects", intersects2);
          // console.log(this.scene);
          // const intersect = intersects2[0];
          // console.log("3D Coordinate:", intersect.point);

          // this.leftWrist.copy(intersect.point);
          // this.leftWrist.z = -1; // Adjust z coordinate if needed
          // console.log("3D wrist position:", this.leftWrist);
          // }

          for (const landmark of result.landmarks) {
            this.drawingUtils.drawLandmarks(landmark, {
              radius: (data) =>
                DrawingUtils.lerp(data.from.z, -0.15, 0.1, 5, 1),
            });
            this.drawingUtils.drawConnectors(
              landmark,
              PoseLandmarker.POSE_CONNECTIONS
            );
          }
          this.w_ctx_2.restore();
        }
      );
    },
    imageSegmentation: function () {
      this.w_canvas_3 = document.getElementById("canvas3");
      this.w_ctx_3 = this.w_canvas_3.getContext("2d", {
        willReadFrequently: true,
      });
    },
    poseDetection: function () {
      /////////////////////////////////////////////////
      /////////////////////////////////////////////////
      /////////////////////////////////////////////////
      /////////////////////////////////////////////////

      // Get DOM elements

      console.log("creating image seg?");
      console.log(this.imageSegmenter);
      // this.createImageSegmenter()

      // List cameras and microphones.
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            console.log(device);
          });
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });

      //  POSE LANDMARKER HERE

      this.w_canvas_2 = document.getElementById("canvas2");
      this.w_ctx_2 = this.w_canvas_2.getContext("2d", {
        willReadFrequently: true,
      });
      this.drawingUtils = new DrawingUtils(this.w_ctx_2);

      // Get segmentation from the webcam
      let lastWebcamTime = -1;

      // !!!!!

      setTimeout(() => {
        this.predictWebcam();
      }, 1000);

      // Enable the live webcam view and start imageSegmentation.

      /////////////////////////////////////////////////
      /////////////////////////////////////////////////
      /////////////////////////////////////////////////
      /////////////////////////////////////////////////
    },

    initThree: function () {
      // Set up the scene
      this.scene = new THREE.Scene();
      this.camera = new THREE.PerspectiveCamera(75, 9 / 16, 0.1, 1000);
      this.renderer = new THREE.WebGLRenderer({ alpha: true });
      this.renderer.setSize(720, 1280);
      this.renderer.setClearColor(0xff0000, 0); // the default
      let three_parent = document.getElementById("threeParent");
      this.c_element = three_parent.appendChild(this.renderer.domElement);
      this.c_element.id = "three_canvas";

      // Get the 2D canvas context

      this.controls = new OrbitControls(this.camera, this.renderer.domElement);

      // Add lighting
      this.light = new THREE.AmbientLight(0x404040, 10);
      this.scene.add(this.light);

      this.leftShoulderPoint = new THREE.Object3D();
      this.rightShoulderPoint = new THREE.Object3D();
      this.scene.add(this.leftShoulderPoint);
      this.scene.add(this.rightShoulderPoint);

      // Load the GLTF model

      this.loader = new GLTFLoader();

      this.dracoLoader = new DRACOLoader();

      this.dracoLoader.setDecoderPath(
        "https://www.gstatic.com/draco/v1/decoders/"
      );
      this.loader.setDRACOLoader(this.dracoLoader);

      this.loader.load(
        "https://matt-stent-testing.ams3.cdn.digitaloceanspaces.com/CricketShirt_AR_2023_welshfire_D2.glb",
        (gltf) => {
          // console.log(gltf);
          this.tshirtMesh = gltf.scene.children[0];

          this.rotationPoint = new THREE.Object3D();
          this.rotationPoint.position.set(0, 0, 0);
          this.tshirtMesh.position.set(0, 0, 0);
          this.rotationPoint.add(this.tshirtMesh);

          this.scene.add(this.rotationPoint);
          this.skeleton = new THREE.SkeletonHelper(this.tshirtMesh);
          this.scene.add(this.skeleton);

          console.log("mesh is...");
          this.tshirtMesh.children[0].visible = false;
          this.tshirtMesh.children[1].visible = false;
          // this.tshirtMesh.children[2].visible = false;

          console.log(this.tshirtMesh);

          this.skeleton.bones.forEach((bone, index) => {
            // console.log(index, bone.name);
          });
          this.animate();
        }
      );

      this.camera.position.z = 5;

      this.raycaster = new THREE.Raycaster();

      const planeGeometry = new THREE.PlaneGeometry(16, 9);
      const planeMaterial = new THREE.MeshBasicMaterial({
        color: 0x00ff00,
        side: THREE.DoubleSide,
        visible: false,
      });
      this.plane = new THREE.Mesh(planeGeometry, planeMaterial);
      this.plane.position.set(0, 0, -1); // Adjust position as necessary
      this.scene.add(this.plane);
    },
    animate() {
      requestAnimationFrame(this.animate);
      this.renderer.render(this.scene, this.camera);
    },
    updateSkeleton: function () {
      try {
        // this.tshirtMesh.children[1].rotation.x += 0.1;
        // this.tshirtMesh.children[1].rotation.y += 0.1;

        const shoulderL = { x: 0, y: 0, z: 0 };
        const shoulderR = { x: 0, y: 0, z: 0 };
        let b_l = 7;
        let b_r = 31;

        // arm bone rotation
        let vertL = this.mpLS.y - this.mpLE.y;
        let horizL = this.mpLS.x - this.mpLE.x;
        let vertR = this.mpRS.y - this.mpRE.y;
        let horizR = this.mpRS.x - this.mpRE.x;
        this.angleL.push(Math.atan(horizL / vertL));
        this.angleR.push(Math.atan(horizR / vertR));

        let lAvg = 0;
        let rAvg = 0;

        if (this.angleL.length > 5) {
          this.angleL.shift();
        }
        if (this.angleR.length > 5) {
          this.angleR.shift();
        }

        this.angleL.forEach((el) => {
          lAvg += el;
        });
        this.angleR.forEach((el) => {
          rAvg += el;
        });

        lAvg = lAvg / this.angleL.length;
        rAvg = rAvg / this.angleR.length;

        this.skeleton.bones[b_l].rotation.y = lAvg + 0.785398 * 1.25;
        this.skeleton.bones[b_r].rotation.y = rAvg - 0.785398 * 1.25;
        3;

        // shoulder z axis rotation
        console.log("pre look");
        this.leftShoulderPoint.position.set(
          this.mpLS.x,
          this.mpLS.y,
          this.mpLS.z
        );
        this.rightShoulderPoint.position.set(
          this.mpRS.x,
          this.mpRS.y,
          this.mpRS.z
        );

        // Assuming `this.leftShoulderPoint` and `this.rightShoulderPoint` are `Object3D` instances
        this.leftShoulderPoint.position.set(
          this.mpLS.x,
          this.mpLS.y,
          this.mpLS.z
        );
        this.rightShoulderPoint.position.set(
          this.mpRS.x,
          this.mpRS.y,
          this.mpRS.z
        );

        // Make `leftShoulderPoint` look at `rightShoulderPoint`
        this.leftShoulderPoint.lookAt(this.rightShoulderPoint.position);

        // Use quaternion to get the rotation
        const quaternion = new THREE.Quaternion().setFromRotationMatrix(
          this.leftShoulderPoint.matrixWorld
        );

        // Convert quaternion to Euler angles if needed
        const euler = new THREE.Euler().setFromQuaternion(quaternion, "XYZ");

        // uncomment for rotation
        // this.shoulderAngleDegrees = euler.y;
        // this.tshirtMesh.rotation.y = euler.y * 10;

        this.shoulderZ = this.mpLS.z - this.mpRS.z;
        this.shoulderX = this.mpLS.x - this.mpRS.x;
        this.shoulderAngle = Math.atan(this.shoulderX / this.shoulderZ);
        // this.shoulderAngleDegrees = parseInt(
        //   this.shoulderAngle * (180 / Math.PI)
        // );

        // this.shoulderAngleDegrees = parseInt(this.shoulderX * 100);
        // this.tshirtMesh.children[1].rotation.y = this.shoulderAngle;
        // console.log("*************************************");

        this.yDelta++;
        if (this.yDelta > 50) {
          this.yDelta = 1;
        }
        let delta = (this.yDelta / 50) * 1.6 - 0.8;

        // console.log("delta is", delta);
        // this.skeleton.bones[b_l].rotation.y = delta;
        // this.skeleton.bones[b_r].rotation.y = delta;
        // this.skeleton.bones[1].position.x = delta * 10;
        // this.skeleton.bones[2].position.x = delta * 10;
        // this.skeleton.bones[3].position.x = delta * 10;

        // Update other bones similarly

        // mediapipe / name / mixamo
        // 0 - nose - 5
        // 11 - left shoulder - 7
        // 12 - right shoulder - 31
        // 13 - left elbow - 8
        // 14 - right elbow - 32
        // 15 - left wrist - 10
        // 16 - right wrist - 34
        // 17 - left pinky - 30
        // 18 - right pinky - 54
        // 19 - left index - 18
        // 20 - right index - 42
        // 23 - left hip - 0 or 55
        // 24 - right hip - 0 or 60
        // 25 - left knee - 56
        // 26 - right knee - 61
      } catch {
        console.log("err");
      }
    },

    animate: function () {
      this.controls.update();
      requestAnimationFrame(this.animate);
      // const points = getSkeletalPoints();

      // this.sphere.position.set(
      // this.leftWrist.x,
      // this.leftWrist.y,
      // this.leftWrist.z
      // );

      this.skeleton.bones[10].position.set(
        this.leftWrist.z,
        this.leftWrist.y,
        this.leftWrist.z
      );

      this.updateSkeleton();
      this.renderer.render(this.scene, this.camera);

      // console.log("skeleton check");
      // console.log(this.sphere);
      // console.log(this.skeleton);
      // console.log("********????????");
    },
  },
};
</script>
<style lang="scss">
#three_canvas {
  position: absolute;
  top: 1vh;
  left: 40vw;
  z-index: 1;
}
.cameraViewContainer {
  h1 {
    font-size: 200px;
    color: white;
    margin-top: 300px;
  }
  .checkbox_holder {
    position: absolute;
    left: 5vw;
    top: 5vh;
  }

  #cameraBox {
  }
  video {
    clear: both;
    // display: none;
    width: 1280px;
    height: 720px;
  }
  canvas {
    clear: both;
    width: 1280px;
    height: 720px;
  }
  #webcam {
    // display: none;
    z-index: 1;
  }
  #canvas {
    position: absolute;
    display: none;
    z-index: 10;
  }
  #canvas2 {
    position: absolute;

    z-index: 20;
  }

  #canvas3 {
    position: absolute;

    z-index: 10;
  }
  #blurbg {
    position: absolute;

    width: 1280px;
    height: 720px;
    top: 0;
    left: 0;
  }
  #clickButton {
    font-size: 2vw;
    position: absolute;
    display: none;
    // top: 100px;
    // left: 100px;
  }
  .hidden {
    // opacity: 0;
  }
  .vidPreviewC {
    position: absolute;
    top: 15vh;
    left: 25vw;
    z-index: 10;

    transform: rotate(-90deg);
  }
  .checkbox_container {
    font-size: 2.5vw;
    input {
      width: 100px;
      transform: scale(2);
    }
  }
  #angleShow {
    position: absolute;
    top: 90vh;
    left: 80vw;
    color: black;
    font-size: 2vw;
  }
}
</style>
