import { createRouter, createWebHistory } from "vue-router";
import SpinHomeView from "../views/spintowin/SpinHomeView.vue";
import MediaPipe from "../views/MediaPipeView.vue";
const routes = [
  {
    path: "/visualiser",
    name: "visualiser",
    meta: { title: "LED Visualiser" },
    component: () =>
      import(/* webpackChunkName: "visualiser" */ "../views/LEDConfig.vue"),
  },
  {
    path: "/trust",
    name: "ftrust",
    meta: { title: "Trust API" },
    component: () =>
      import(/* webpackChunkName: "visualiser" */ "../views/TrustView.vue"),
  },
  {
    path: "/segmentation",
    name: "segmentation",
    meta: { title: "MSCT Segmentation Demo" },
    component: MediaPipe,
  },
  {
    path: "/comms",
    name: "comms v2",
    meta: { title: "Comms v2 Frontend" },
    component: () =>
      import(/* webpackChunkName: "comms" */ "../views/Comms2.vue"),
  },
  {
    path: "/spintowin",
    name: "spinhome",
    component: SpinHomeView,
  },
  {
    path: "/soup",
    name: "souphome",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SoupView.vue"),
  },
  {
    path: "/shop",
    name: "wchome",
    meta: { title: "WooCommerce Api Testing" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/WooView.vue"),
  },
  {
    path: "/t2map",
    name: "t2map",
    meta: { title: "t2map" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/T2Map.vue"),
  },
  {
    path: "/ble",
    name: "ble",
    meta: { title: "BLE Testing" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/BLEView.vue"),
  },
  {
    path: "/spintowin/config",
    name: "config",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/spintowin/ConfigView.vue"
      ),
  },
  {
    path: "/spintowin/config/switch",
    name: "configswitch",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/spintowin/ConfigSwitchView.vue"
      ),
  },
  {
    path: "/spintowin/config/edit",
    name: "configedit",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/spintowin/ConfigEditView.vue"
      ),
  },
  {
    path: "/spintowin/config/create",
    name: "configcreate",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/spintowin/ConfigCreateView.vue"
      ),
  },
  {
    path: "/spintowin/activation",
    name: "activation",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/spintowin/ActivationView.vue"
      ),
  },
  {
    path: "/souper",
    name: "souper",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/SouperView.vue"),
  },

  {
    path: "/dmx",
    name: "dmx",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MOIRView.vue"),
  },

  {
    path: "/threesouper",
    name: "threesouper",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ThreeSouperView.vue"),
  },
  {
    path: "/",
    name: "home",
    meta: { title: "Matt Stent - Creative Technology" },
    component: () =>
      import(/* webpackChunkName: "home" */ "../views/HomeView.vue"),
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to);
  document.title = to.meta.title;
  next();
});

export default router;

// /f/f/f/f/f/f/f/f/f/f/f/f/

// import Vue from "vue";
// import VueRouter from "vue-router";
// import SpinHomeView from "../views/spintowin/SpinHomeView.vue";

// Vue.use(VueRouter);

// const routes = [
//   {
//     path: "/",
//     name: "home",
//     component: SpinHomeView,
//   },
//   {
//     path: "/config",
//     name: "config",
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () =>
//       import(
//         /* webpackChunkName: "about" */ "../views/spintowin/ConfigView.vue"
//       ),
//   },
//   {
//     path: "/config/switch",
//     name: "configswitch",

//     component: () =>
//       import(
//         /* webpackChunkName: "about" */ "../views/spintowin/ConfigSwitchView.vue"
//       ),
//   },
//   {
//     path: "/config/edit",
//     name: "configedit",

//     component: () =>
//       import(
//         /* webpackChunkName: "about" */ "../views/spintowin/ConfigEditView.vue"
//       ),
//   },
//   {
//     path: "/config/create",
//     name: "configcreate",

//     component: () =>
//       import(
//         /* webpackChunkName: "about" */ "../views/spintowin/ConfigCreateView.vue"
//       ),
//   },
//   {
//     path: "/activation",
//     name: "activation",

//     component: () =>
//       import(
//         /* webpackChunkName: "about" */ "../views/spintowin/ActivationView.vue"
//       ),
//   },
// ];

// const router = new VueRouter({
//   mode: "history",
//   base: process.env.BASE_URL,
//   routes,
// });

// export default router;
