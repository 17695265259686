<template>
  <div id="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->

    <p>
      <router-link class="btn btn-xxl btn-secondary" to="/spintowin/config"
        >Config</router-link
      >
    </p>
    <p>
      <router-link class="btn btn-xxl btn-primary" to="/spintowin/activation"
        >Activation</router-link
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
};
</script>
<style lang="scss">
#home {
  margin-top: 200px;
  .btn-xxl {
    font-size: 5vw;
    border-radius: 1vw;
    padding: 1vw;
    width: 50vw;
    margin: 1vw;
  }
}
</style>
